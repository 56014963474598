@import '../../styles/variables.scss';

.menu_divider {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px auto 10px;
}

.menu {
  // padding: 0 20px 20px 20px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.15);
}

:global(.activeLink) {
  color: $light-blue;
}