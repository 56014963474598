.ageBlockContainer {
  font-family: 'ProximaNova';
  h2 {
    font-size: 1.625rem;
    text-align: left;
    margin-top: 6px;
    font-family: 'ProximaNova';
    font-weight: 600;
    line-height: 31.67px;
    margin-bottom: 0.625rem; // 10px
  }
  p, ol, ul {
    font-size: 1.125rem;
    font-weight: 400;
    text-align: left;
    margin-bottom: 1.875rem; // 30px
    line-height: 26px;
  }
  ol, ul {
    padding-left: 1.156rem; // 18.5px
  }
  :global(.noMargin) {
    margin-bottom: 0;
  }
  strong {
    font-family: 'ProximaNova Semi Bold';
  }
  ul {
    padding-left: 20px;
    p {
      margin-bottom: 1rem;
    }
  }
}
