@import '../../styles/variables.scss';

$transition-speed: 0.6s;
$left-margin: 20px;

.wrapper {
  min-height: 100vh;
  overflow-y: auto;
  padding: 0;
  max-width: 100%;
  width: 100%;
}

.stickyHeaderContainer {
  z-index: 5000;
}

.lowerHeaderActive {
  transition: all $transition-speed;
  .kid_timline_wrapper {
    max-height: 140px;
    overflow: hidden;
    transition: all $transition-speed;
  }
  .badge {
    transition: all $transition-speed;
    width: 50px;
    height: 48px;
    font-size: 0;
  }
  .child_margin {
    transition: all $transition-speed;
    width: 50px;
    height: 48px;
  }

  button svg {
    width: 0 !important;
    transition: all $transition-speed;
  }

  :global(.ant-btn) {
    display: none;
  }

  :global(.ant-badge) > img {
    transition: all $transition-speed;
    width: 50px;
    height: 48px;
  }
}

.kid_timline_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //  max-width: $screen-max-width;
  margin: 0 auto;
  padding-top: 0;
  width: 100%;
  transition: all $transition-speed;
  max-height: 250px;
  box-shadow: 0px 20px 17px -17px rgba(0, 0, 0, 0.5);

  :global(.ant-badge) > img {
    transition: all $transition-speed;
  }
}

.cardContainer {
  cursor: pointer;
  width: auto;
  position: absolute;
  margin-left: $left-margin;
  z-index: 11 !important;
}

.parallax {
  // max-width: 375px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.badge {
  width: 147.6px;
  height: 140.4px;

  margin: auto;
  margin-bottom: 20px;
  background-image: url('../../assets/images/Badge.svg');
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  color: $white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  transition: all $transition-speed;
}

.kid_section {
  display: flex;
  justify-content: space-between;
}

.child_margin {
  // margin: 1rem;
  transition: all $transition-speed;
  width: 144px;
  height: 138px;
}

.ageBlock {
  width: $screen-max-width;
  // background-color: orange;
  position: relative;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.ageBlockAge {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 25px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  z-index: 8;
}

.AgeTrigger {
  width: 50px;
  height: 2px;
  // background-color: yellow;
  background-color: transparent;
  top: 40px;
  right: 30px;
  padding: 0;
  text-align: center;
  vertical-align: top;
  position: absolute;
  box-sizing: border-box;
  z-index: 8;
}

.TopTrigger {
  width: 50px;
  height: 2px;
  // background-color: purple;
  background-color: transparent;
  top: 40px;
  right: 30px;
  padding: 0;
  text-align: center;
  vertical-align: top;
  position: absolute;
  box-sizing: border-box;
  z-index: 8;
}

.ageBlockContainer {
  text-align: center;
  width: $screen-max-width;
  height: 745px;
  //  border-bottom: 1px solid black;
  border-bottom: none;
}

.ageBlockContent {
  margin: 0 auto;
  // max-width: 60rem;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
  height: 100%;
}

.ageImage {
  width: 60px;
  // height: 131px;
  position: relative;
}

.ageImage2Digits {
  width: 100px;
  height: auto;
  right: 10px;
  // height: 105px;
  position: relative;
}

.ageSign {
  color: $blue-bg;
  background-color: white;
  border: 2px solid $blue-bg;
  width: auto;
  font-weight: bold;
  border-radius: 29px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 13px 22px;
  display: inline-block;
  // z-index: 9999;
  position: relative;
  margin-left: $left-margin;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
}

.GiftCard {
  z-index: 2000;
  width: 75px;
  height: 75px;
  margin: 5px 0;
  position: relative;
}

.GiftCardForType {
  z-index: 2000;
  margin: 5px 0;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border: 1px solid black;
  }
}

.GiftCardBox {
  width: 25px;
  height: 25px;
  border-radius: 0;
  margin: 0;
  float: left;
}

.backgroundImagePlx {
  position: relative;
}

.backgroundImagePlxPlus {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.backgroundImageContainer_level_2 {
  z-index: 0;
}

.backgroundImage1 {
  position: relative;
  width: 100%;
  height: auto;
}

.backgroundImageBitBubble {
  height: auto;
  position: relative;
  top: 0px;
  left: $left-margin;
  width: 314px;
  height: 414px;
}

:global(.plusIcon) {
  width: 50px;
  height: auto;
  cursor: pointer;
  position: relative;
  //z-index: 999999999;
}

@keyframes pulse-shadow {
  from {
    filter: contrast(75%);
  }
  to {
    filter: contrast(100%);
  }
}

.plus {
  position: absolute;
  animation: pulse-shadow 2s infinite alternate;
  filter: contrast(100%);
}

.plus0_1 {
  left: 41.6%;
  right: 45.07%;
  top: 34.85%;
  bottom: 58.99%;
}

.plus1_1 {
  left: 74.4%;
  right: 12.27%;
  top: 11.33%;
  bottom: 82.51%;
}

.plus1_2 {
  left: 44%;
  right: 42.67%;
  top: 73.03%;
  bottom: 20.81%;
}

.plus2_1 {
  left: 79.2%;
  right: 7.47%;
  top: 28.33%;
  bottom: 65.52%;
}
.plus2_2 {
  left: 36.27%;
  right: 50.4%;
  top: 77.09%;
  bottom: 16.75%;
}

.plus3_1 {
  left: 73.07%;
  right: 13.6%;
  top: 89.04%;
  bottom: 4.8%;
}

.plus4_1 {
  left: 38.13%;
  right: 48.53%;
  top: 45.2%;
  bottom: 48.65%;
}

.plus5_1 {
  left: 72.53%;
  right: 14.13%;
  top: 15.89%;
  bottom: 77.96%;
}

.plus5_2 {
  left: 29.87%;
  right: 56.8%;
  top: 89.66%;
  bottom: 4.19%;
}

.plus6_1 {
  left: 81.6%;
  right: 5.07%;
  top: 15.27%;
  bottom: 78.57%;
}

.plus6_2 {
  left: 34.13%;
  right: 52.53%;
  top: 83.25%;
  bottom: 10.59%;
}

.plus7_1 {
  left: 36.8%;
  right: 49.87%;
  top: 15.27%;
  bottom: 78.57%;
}

.plus7_2 {
  left: 73.33%;
  right: 13.33%;
  top: 87.68%;
  bottom: 6.16%;
}

.plus8_1 {
  left: 49.6%;
  right: 37.07%;
  top: 44.33%;
  bottom: 49.51%;
}

.plus8_2 {
  left: 26.93%;
  right: 59.73%;
  top: 83.13%;
  bottom: 10.71%;
}

.plus9_1 {
  left: 39.73%;
  right: 46.93%;
  top: 46.06%;
  bottom: 47.78%;
}

.plus9_2 {
  left: 67.2%;
  right: 19.47%;
  top: 89.9%;
  bottom: 3.94%;
}

.plus10_1 {
  left: 38.67%;
  right: 48%;
  top: 17.12%;
  bottom: 76.72%;
}

.plus10_2 {
  left: 79.47%;
  right: 7.2%;
  top: 60.47%;
  bottom: 33.37%;
}

.plus11_1 {
  left: 61.33%;
  right: 25.33%;
  top: 19.09%;
  bottom: 74.75%;
}

.plus11_2 {
  left: 38.67%;
  right: 48%;
  top: 56.53%;
  bottom: 37.32%;
}

.plus12_1 {
  left: 77.6%;
  right: 9.07%;
  top: 18.23%;
  bottom: 75.62%;
}

.plus13_1 {
  left: 36.67%;
  right: 50%;
  top: 86.08%;
  bottom: 7.76%;
}

.plus14_1 {
  left: 32.53%;
  right: 54.13%;
  top: 58.99%;
  bottom: 34.85%;
}

.plus14_2 {
  left: 70.4%;
  right: 16.27%;
  top: 80.42%;
  bottom: 13.42%;
}

.plus15_1 {
  left: 33.87%;
  right: 52.8%;
  top: 37.81%;
  bottom: 56.03%;
}

.plus15_2 {
  left: 60.27%;
  right: 26.4%;
  top: 73.65%;
  bottom: 20.2%;
}

.plus16_1 {
  left: 75.2%;
  right: 11.47%;
  top: 35.22%;
  bottom: 58.62%;
}

.plus16_2 {
  left: 36.53%;
  right: 50.13%;
  top: 60.84%;
  bottom: 33%;
}

.plus17_1 {
  left: 18.4%;
  right: 68.27%;
  top: 39.66%;
  bottom: 54.19%;  
}

.plus17_2 {
  left: 74.13%;
  right: 12.53%;
  top: 68.84%;
  bottom: 25%;
}

.plus18_1 {
  left: 37.86%;
  top: 39.78%;

}

.plus18_2 {
  left: 80.26%;
  top: 66.37%;
}

.backgroundImageSmallCloud {
  width: 80px;
  height: auto;
  position: absolute;
  top: -170px;
  left: 56%;
}

.backgroundImageFox {
  height: auto;
  position: relative;
  top: -30px;
  left: 0;
}

.backgroundImageFishes {
  width: 100%;
  height: auto;
  margin-top: 100px;
  position: relative;
  top: 100px;
  left: 0;
}

.backgroundImageJellyfishes {
  width: 100%;
  height: auto;
  position: absolute;
  left: -20%;
  top: -100px;
  margin-top: 0;
}

.backgroundImageLeaf {
  width: 150%;
  max-width: 150%;
  height: auto;
  position: absolute;
  top: 150px;
  left: 0px;
  z-index: 999999999999999999;
}

.backgroundImageFlipFlops {
  width: 30%;
  height: auto;
  position: absolute;
  top: 500px;
  right: 20%;
}

.backgroundImageBall {
  width: 30%;
  height: auto;
  position: absolute;
  top: 0px;
  left: 50%;
}

.backgroundImageSunglasses {
  width: 30%;
  height: auto;
  position: absolute;
  top: 400px;
  right: 40%;
}

.backgroundImageFlower {
  width: 30%;
  height: auto;
  position: absolute;
  top: 550px;
  left: 0px;
}

.backgroundImagePolarBear {
  width: 90%;
  max-width: 200%;
  height: auto;
  height: auto;
  position: absolute;
  top: 320px;
  left: 10px;
}

.backgroundImageSun {
  width: 162px;
  height: auto;
  height: auto;
  position: absolute;
  top: 100px;
  left: 45%;
}

.backgroundImageRollerCoaster {
  width: 100%;
  height: auto;
  height: auto;
  position: absolute;
  top: 350px;
  left: 0;
}

.backgroundImageLolly1,
.backgroundImageLolly2,
.backgroundImageLolly3 {
  width: 55%;
  height: auto;
  position: relative;
  left: 25%;
}

.backgroundImageLolly1 {
  top: 100px;
}

.backgroundImageLolly2 {
  top: 100px;
}

.backgroundImageLolly3 {
  top: 100px;
}

.backgroundImageDiscoBall {
  width: 100%;
  height: auto;
  position: absolute;
  top: 45px;
  left: 10%;
}

.backgroundImagePinkRobot {
  width: 80%;
  height: auto;
  position: absolute;
  top: 470px;
  right: -15%;
}

.backgroundImageGreenRobot {
  width: 80%;
  height: auto;
  position: absolute;
  top: 350px;
  left: -5%;
}

.backgroundImagePlanet {
  width: 25%;
  top: 150px;
  height: auto;
  position: absolute;
  left: 65%;
}

.backgroundImageSpaceMan {
  width: 85%;
  height: auto;
  position: relative;
  top: 290px;
  right: -18%;
}

.backgroundImageAstroTina {
  width: 140%;
  max-width: 200%;
  height: auto;
  position: relative;
  top: -245px;
  right: 15%;
}

.backgroundImageNeonCoffee,
.backgroundImageNeonTreat,
.backgroundImageNeonDonut,
.backgroundImageNeonMilkshake {
  width: 50%;
  height: auto;
  position: absolute;
}

.backgroundImageNeonCoffee {
  top: 0px;
  right: 0px;
}
.backgroundImageNeonTreat {
  top: 120px;
  left: 35%;
}
.backgroundImageNeonDonut {
  top: 300px;
  left: 5%;
}
.backgroundImageNeonMilkshake {
  top: 460px;
  right: 10%;
}

.backgroundImageSkateShadow {
  width: 45%;
  height: auto;
  position: relative;
  top: 190px;
  margin: auto;
  left: 15%;
  transform: translateZ(0);
}

.backgroundImageSkateboard {
  width: 70%;
  height: auto;
  position: relative;
  top: 150px;
  margin: auto;
  left: 0%;
}

.backgroundImageStamp {
  width: 80%;
  height: auto;
  position: relative;
  top: 100px;
  right: -55px;
  margin: auto;
}

.backgroundImageStamp2 {
  width: 100%;
  height: auto;
  position: relative;
  top: 100px;
  left: 60px;
  margin: auto;
}

.backgroundImageSuitcase {
  width: 100%;
  max-width: 200%;
  height: auto;
  position: relative;
  top: 170px;
  right: 0px;
  margin: auto;
}

.backgroundImageCloud {
  width: 60%;
  height: auto;
  position: relative;
  top: 80px;
  left: 40px;
  margin: auto;
}

.backgroundImageSkyCity {
  width: 70%;
  height: auto;
  position: relative;
  top: 310px;
  left: 20px;
  margin: auto;
}

.backgroundImagePolaroid {
  width: 120%;
  max-width: 500%;
  height: auto;
  position: relative;
  top: 200px;
  left: -9%;
  margin: auto;
}

.backgroundImageGaming {
  width: 100%;
  max-width: 500%;
  height: auto;
  position: relative;
  top: 100px;
  left: 20px;
  margin: auto;
}

.backgroundImageTickets {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  margin: auto;
}

.backgroundImageHeadphones {
  width: 90%;
  height: auto;
  position: relative;
  top: 90px;
  left: 10%;
  margin: auto;
}

.backgroundImageCloudDreams {
  width: 100%;
  height: auto;
  position: relative;
  top: 100px;
  left: 20px;
  margin: auto;
}

.backgroundImageSprayPaint {
  width: 35%;
  height: auto;
  position: relative;
  top: -70px;
  left: 30%;
  margin: auto;
}

.backgroundImageLighteningBolt1 {
  width: 27%;
  height: auto;
  position: relative;
  top: 100px;
  left: 25%;
  margin: auto;
}

.backgroundImageLighteningBolt2 {
  width: 20%;
  height: auto;
  position: relative;
  top: 220px;
  left: -5%;
  margin: auto;
}

.backgroundImageBattery {
  width: 100%;
  height: auto;
  position: relative;
  top: 260px;
  left: 1%;
  margin: auto;
}

.backgroundImageTaxis {
  width: 100%;
  height: auto;
  position: relative;
  top: 70px;
  left: 0;
  margin: auto;
  z-index: 9;
}

.backgroundImageTrafficLights {
  width: 55%;
  height: auto;
  position: relative;
  top: 50px;
  left: 10%;
  margin: auto;
  z-index: 9;
}

.backgroundImageHats {
  width: 66%;
  max-width: 200%;
  height: auto;
  position: relative;
  top: 420px;
  left: 0%;
  margin: auto;
}

.backgroundText {
  width: 180px;
  height: auto;
  position: absolute;
  top: 120px;
  left: 100px;
  margin: auto;
  z-index: 9;
  text-align: left;
  font-size: 16px;
  p {
    font-size: 18px;
  }
  h1 {
    font-family: 'ProximaNova Bold';
    font-size: 20px;
    color: $text-dark;
    line-height: 1.4em;
    letter-spacing: -0.408px;
    margin-top: 0px;
    margin-bottom: 1.2em;
  }
}

.backgroundImageLighteningBoltLarge {
  width: 150px;
  height: auto;
  position: absolute;
  top: 300px;
  left: 50%;
  margin: auto;
  margin-left: -120px;
}

.bottomFrame {
  height: 300px;
  background-image: url('../../assets/images/timeline/seaBed.png');
  background-repeat: no-repeat;
  background-size: $screen-max-width auto;
  position: fixed;
  background-position: bottom;
  bottom: 0;
  right: 0;
  z-index: 60000;
}

.modalBackground {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.giftCardImageContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40px;
  width: 100%;
  .giftCardImage {
    width: 275px;
    height: 275px;
  }
  .giftCardImageValueContainer {
    position: absolute;
    top: 0;
    width: 322px;
    height: 275px;
    font-family: 'intro-inline';
    .giftCardImageValue {
      font-size: 50px;
      color: white;
      max-width: 92px;
      overflow: hidden;
      display: block;
      width: 92px;
      text-align: center;
      height: 55px;
      div {
        height: 100%;
        display: flex !important;
        width: 100%;
        justify-content: center;
        div {
          display: block !important;
          width: 100%;
        }
      }
    }
    .giftCardImageCurrency {
      font-size: 18px;
      color: white;
      display: block;
      max-width: 92px;
      overflow: hidden;
      display: block;
      width: 92px;
      text-align: center;
    }
  }
}

.giftMessageContainer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0 40px;
  color: white;
  text-align: center;
  font-weight: bold;
}

$giftbox-bottom: 30px;

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: $giftbox-bottom;
  }
  50% {
    bottom: $giftbox-bottom + 10px;
  }
}

.giftBoxContainer {
  // position: absolute;
  // bottom: $giftbox-bottom;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  animation: MoveUpDown 1s linear infinite;
  // a {
  //   width: 80px;
  //   cursor: pointer;
  //   height: 100%;
  //   img {
  //     width: 100%;
  //   }
  // }
}
.openMeContainer {
  // position: absolute;
  bottom: $giftbox-bottom;
  width: 100%;
  display: flex;
  justify-content: center;
  .openMe {
    width: 75px;
    display: flex;
    justify-content: center;
    margin-left: 180px;
  }
}

.giftCardCloseButton {
  position: absolute;
  right: 10px;
  top: 10px;
}

.giftCardMosaicMarker {
  position: absolute;
  left: 10px;
  bottom: 210px;
  height: 32px;
  width: 32px;
}

.slider {
  width: 142px;
  height: 136px;
}
