@import '../../styles/variables.scss';

.slider_wrap {
  padding-top: 80px;
}

.badge {
  width: 124px;
  height: 124px;
  margin: auto;
  margin-bottom: 20px;
  background-image: url('../../assets/images/Badge.svg');
  background-repeat: no-repeat;
  background-size: contain;
  display: grid;
  place-items: center;
  color: $white;
  font-weight: bold;

  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.165px;
}

.dotClass {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  border-radius: 40px;
  text-align: center;
}

.dotClass li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  padding: 0;
}

.dotClass li {
  & button {
    border: 1px solid $blue-bg;
    background: transparent;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
    padding: 0;
    outline: none;
  }
}

.dotClassWhite {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  border-radius: 40px;
  text-align: center;
}

.dotClassWhite li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  padding: 0;
  height: 15px;
  width: 15px;
}

.dotClassWhite li {
  & button {
    border: 1px solid $white;
    background: transparent;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
    outline: none;
    padding: 0;
  }
}

.dotClass :global(.slick-active) {
  & button {
    background: $blue-bg !important;
  }
}

.dotClassWhite :global(.slick-active) {
  & button {
    background: $white !important;
  }
}
