@import './styles/variables.scss';
@import './styles/fonts.scss';

// body {
//   margin: 0;
//   font-family: 'ProximaNova';
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// body,
// html {
//   height: 100%;
//   width: 100%;
// }

// #root {
//   height: 100%;
// }

// button:-moz-focus-inner {
//   border: 0;
//   padding: 0;
// }
// .backgroundImageContainer_0_1 {
//   position: absolute;
//   top: 105px;
//   left: 30px;
//   z-index: 0;
// }


// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: 1px solid $blue-bg;
//   -webkit-text-fill-color: $blue-bg;
//   transition: background-color 5000s ease-in-out 0s;
//   box-shadow: $blue-bg;
//   -webkit-box-shadow: $blue-bg;
// }

.ReactModalPortal,
.ReactModalPortal > div
 {
  z-index: 9999999999;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.__floater__open {
  transition: opacity 0.5s ease 0s !important;
  transition-property: opacity !important;
}
