@import '../../styles/variables.scss';

.gift_divider {
  margin: 0 auto 9px;
  width: 100%;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.2);
}

.gift_cards_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px 32px 20px;
  width: 100%;
  margin: 0 auto;
}

.day {
  font-family: 'ProximaNova';
  font-size: 19px;
  font-weight: 700;
  width: 100%;
  margin-top: -3px;
  margin-bottom: 4px;
}

// .gift_info_wrapper {
//   display: flex;
//   justify-content: space-between;
//   max-width: 270px;
//   width: 100%;
//   margin: 12px 0 48px;
// }
.gift {
  width: 102px;
  height: 102px;
}

.ava {
  width: 106px;
  height: 106px;
  cursor: pointer;
}
