@import '../../styles/variables.scss';

.child_detail_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  .ava {
    color: $white;
    font-weight: bold;
    display: grid;
    place-items: center;
    border-radius: 50%;
    background-color: $blue-bg;
    cursor: pointer;
    box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.15);
  }
  .large_ava {
    width: 144px;
    height: 144px;
  }
  .small_ava {
    width: 70px;
    height: 70px;
  }
  .avatar_picker {
    height: 74px;
    width: 74px;
  }
  .picker_avatar {
    width: 66px;
    height: 66px;
    background-color: transparent;
    box-shadow: none;
    border-radius: unset;
    z-index: 2;
    position: relative;
  }
  .picker_avatar_selected_item {
    top: 10px;
    width: 76px;
    height: 76px;
    position: absolute;
    z-index: 1;
    border-radius: 147px;
    background: linear-gradient(180deg, #fbfbfb 0%, #ffffff 100%);
    border: 3px solid #61dcf3;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .picker_avatar_selected {
    left: 10px;
    width: 105px;
    height: 103px;
    border-radius: 20px;
    background-color: #99bac2;
    position: absolute;
    top: 0px;
  }
}

:global(.avatarSlider .slick-list) {
  padding-left: 10px;
  padding-right: 10px;
}
:global(.slick-prev:before),
:global(.slick-next:before) {
  font-size: 35px;
  color: #043d4d;
}

.sliderContainer {
  // width: $screen-max-width;
  margin: 0 auto;
}
